import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import { addProductStart } from "store/dictionary/actions";

export default function AddTradeProduct({
  show, toggle
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const disableAddButton = false;
  return (
    <Modal isOpen={show} toggle={toggle} centered={true} size="sm">
      <ModalHeader toggle={toggle} tag="h4">
        {t("Add Group")}
      </ModalHeader>
      <ModalBody >
        <AvForm
          className='p-4'
          onValidSubmit={(e, v) => {
            dispatch(addProductStart(v));
          }}
          id="form"
        >
          <Row>
            <Col md="12">
              <div className="mb-3">
                <AvField
                  name="product"
                  label={t("Product Name")}
                  placeholder={t("Enter Product Name")}
                  type="text"
                  errorMessage={t("Enter Product name")}
                  validate={{ required: { value: true } }}
                />
              </div>
            </Col>
          </Row>
          <div className='text-center pt-3 p-2'>
            <Button  disabled = {disableAddButton} type="submit" color="primary" className="">
              {t("Add")}
            </Button>
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}
